import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import SnowFlake from '../SnowFlake/SnowFlake';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { TfiClose } from 'react-icons/tfi';
import { getPolicyWindowVisibility } from '../../../redux/technical/technical-selectors';
import { getAdvertisingBtnVisibility } from '../../../redux/technical/technical-selectors';
import { setAdvertisingBtnVisibility } from '../../../redux/technical/technical-slice';

import s from './AdvertisingWindow.module.scss';

const AdvertisingWindow = () => {
  const { t } = useTranslation();
  const isDesctop = useMediaQuery({ minWidth: 1280 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const policeWindowVisibility = useSelector(getPolicyWindowVisibility);
  const visible = useSelector(getAdvertisingBtnVisibility);
  const dispatch = useDispatch();

  const mobileHeight = screenHeight - 195;

  const toggleVisibility = () => {
    dispatch(setAdvertisingBtnVisibility(!visible));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setAdvertisingBtnVisibility(true));
    }, 5000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  useEffect(() => {
    let timeoutId;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setScreenHeight(window.innerHeight);
      }, 300);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, [screenHeight]);

  return (
    <div>
      <div
        className={`${s.adWindow} ${
          visible && policeWindowVisibility
            ? s.visibleWindow
            : visible && !policeWindowVisibility
            ? s.visible
            : ''
        }`}
        style={{
          height: isDesctop
            ? '435px'
            : isTablet
            ? '380px'
            : `${mobileHeight}px`,
        }}
      >
        {/* <SnowFlake /> */}
        <div className={s.adWindow__topPart}>
          <button className={s.closeBtn} onClick={toggleVisibility}>
            <TfiClose color="#212121" size={20} />
          </button>
          <div
            className={s.adContent}
            style={{ height: isMobile ? `${mobileHeight - 250}px` : '100%' }}
          >
            <p
              className={s.adWindow__textTitle}
              style={{ width: isMobile ? '200px' : '450px' }}
            >
              {t('Dreaming of a pearly white smile?')}
            </p>
            <p className={s.adWindow__title}>
              {t('Special Offer:')} <br />
              <span style={{ color: '#A099CC' }}>{t('Teeth Whitening')}</span>
            </p>
            <p
              className={s.adWindow__text}
              style={{
                textAlign: isMobile ? 'center' : 'left',
                marginTop: isDesctop ? '-15px' : isTablet ? '-10px' : '-5px',
                fontSize: isDesctop ? '20px' : isTablet ? '18px' : '16px',
                color: 'gray',
              }}
            >
              {t('With Beyond II Technology')}
            </p>
            {!isMobile && (
              <div>
                <div className={s.adWindow__listContent}>
                  <p className={s.adWindow__text}>
                    ✔ {t('A bright smile for a long time')}
                  </p>
                </div>
                <div className={s.adWindow__listContent}>
                  <p className={s.adWindow__text}>
                    ✔ {t('Quickly in one session')}
                  </p>
                </div>
                <div className={s.adWindow__listContent}>
                  <p className={s.adWindow__text}>✔ {t('Absolutely safe')}</p>
                </div>
              </div>
            )}
            <div className={s.adWindow__promoWrapper}>
              <div className={s.adWindow__promo}>
                <div
                  className={`${s.adWindow__text} ${s.adWindow__rotatedText}`}
                  style={{
                    textAlign: 'center',
                    color: 'var(--main-color)',
                    lineHeight: '1.2',
                  }}
                >
                  <p>{t('Special')}</p>
                  <p>{t('price')}</p>
                </div>
              </div>
              <p className={s.adWindow__title} style={{ color: '#D00100' }}>
                275€
              </p>
            </div>
          </div>
          {!isMobile && (
            <div className={s.adWindow__showBox}>
              <div className={s.adWindow__showBoxPart}>
                <p
                  className={s.adWindow__text}
                  style={{ color: 'var(--main-color)' }}
                >
                  {t('Before')}
                </p>
              </div>
              <div className={s.adWindow__showBoxPart}>
                <p
                  className={s.adWindow__text}
                  style={{ color: 'var(--main-color)' }}
                >
                  {t('After')}
                </p>
              </div>
            </div>
          )}
        </div>
        {isMobile && <div className={s.adWindow__bottomPart}></div>}
      </div>
    </div>
  );
};

export default AdvertisingWindow;
